import React, {useEffect, useState} from 'react';
import {ErrorAlert, FormTitle, Spinner, SuccessAlert,} from '../utils/commonStyledComponents';
import {
    editPackage,
    fetchAlternativeServicesByAlternativeLabel,
    fetchPractice,
    getDepartureCities,
    getDestinations,
    getPrograms,
    getPackages,
    updatePreview
} from '../utils/api';
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";
import {Grid, Link, Paper} from "@material-ui/core";
import StatusAlert from "../components/StatusAlert";
import {ArrowBack} from "@material-ui/icons";
import OneStepForm from "../components/OneStepForm";
import {PRACTICE_TYPES} from "../components/PracticeItem";
import Button from "../components/Button";
import {useUser} from "../utils/UserSession";
import EditPracticeSidebar from "../components/EditPracticeSidebar";
import {PRACTICE_TYPE_INPS} from "../utils/constants";
import {formatPrice} from "../utils/commonFunctions";
import {WarningAlert} from "../utils/commonStyledComponents";

const EditPractice = () => {
    const {user, setUser} = useUser();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showPracticeError, setShowPracticeError] = useState(false);
    const [showPracticeErrorMessage, setShowPracticeErrorMessage] = useState(null);
    const [showPracticeSuccess, setShowPracticeSuccess] = useState(false);
    const [isExtraServicesLoading, setIsExtraServicesLoading] = useState(false);
    const [practice, setPractice] = useState(null);
    const [destinations, setDestinations] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [periods, setPackages] = useState([]);
    const [departureCities, setDepartureCities] = useState([]);
    const [inps, setInpsList] = useState([]);
    const [extraServices, setExtraServices] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedDepartureCity, setSelectedDepartureCity] = useState(null);
    const [selectedInps, setSelectedInps] = useState(null);
    const [selectedExtraServices, setSelectedExtraServices] = useState([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Si è verificato un errore di caricamento");
    const [showAlert, setShowAlert] = useState(false);
    const [preview, setPreview] = useState(null);
    const {id} = useParams();

    const buildPayload = () => {
        const newPackageItems = [];
        if (selectedDepartureCity) {
            newPackageItems.push(selectedDepartureCity);
        }
        if (selectedInps) {
            newPackageItems.push(selectedInps);
        }
        if (selectedExtraServices && selectedExtraServices?.length > 0) {
            newPackageItems.push(...selectedExtraServices?.map((s) => s.value))
        }
        return {
            "practiceId": practice.id,
            "newPackageId": selectedPackage,
            "newPackageItems": newPackageItems
        };
    }

    const refreshPreview = async () => {
        try {
            const preview = await updatePreview(buildPayload());
            if (preview?.code === 200) {
                setPreview(preview.payload);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const fetchDestinations = async () => {
        try {
            const destinations = await getDestinations(practice.practiceDivisionId);
            setDestinations(destinations);
        } catch (e) {
            console.error("fetchDestination", e);
            setShowErrorMessage(true);
        }
    }
    const fetchPrograms = async () => {
        try {
            const programs = await getPrograms(selectedDestination, practice.practiceDivisionId);
            setPrograms(programs);
        } catch (e) {
            console.error("fetchPrograms", e);
            setShowErrorMessage(true);
        }
    }
    const fetchPackages = async () => {
        try {
            const packages = await getPackages(selectedProgram, practice.practiceDivisionId);
            setPackages([...packages?.filter(x => (x.value !== practice?.buyedPackage?.id) && !x.soldout)]);
        } catch (e) {
            console.error("fetchPackages", e);
            setShowErrorMessage(true);
        }
    }
    const fetchDepartureCities = async () => {
        try {
            const departureCities = await getDepartureCities(selectedPackage, practice.practiceDivisionId);
            setDepartureCities([...departureCities?.filter(x => !x.soldout)]);
        } catch (e) {
            console.error("fetchDepartureCities", e);
            setShowErrorMessage(true);
        }
    }
    const fetchInps = async () => {
        try {
            const alternativeServiceInps = await fetchAlternativeServicesByAlternativeLabel(
                'contributoinps',
                practice.practiceProductDivisionId,
                selectedPackage,
                practice.practiceDivisionId
            );
            setInpsList(alternativeServiceInps);
        } catch (e) {
            console.error("fetchInps", e);
            setShowErrorMessage(true);
        }
    }

    const fetchExtraServices = async () => {
        try {
            setIsExtraServicesLoading(true);
            let alternatives = new Array(10)
                .fill(0)
                .map((p, index) => `Extra${index + 1}`);
            const participationNames = {1: 'PVT', 2: 'INPS', 4: 'AZ', 6: 'INPSN'};
            alternatives = alternatives.concat(alternatives.map(v => `${v}:${participationNames[practice.practiceType]}`))
            alternatives.push(`PAGAMENTO-EXTRA:${participationNames[practice.practiceType]}`)
            const promises = await Promise.all(
                alternatives.map((x) => fetchAlternativeServicesByAlternativeLabel(
                    x,
                    practice.practiceProductDivisionId,
                    selectedPackage,
                    practice.practiceDivisionId
                ))
            );

            let alternativeServices = [...promises.filter((x) => x.val)];
            console.info("alternativeServices", alternativeServices);
            alternativeServices = alternativeServices.filter((altSer, index) => {
                return index === alternativeServices.findIndex(obj => {
                    return obj.alternativeLabel === altSer.alternativeLabel;
                });
            });
            alternativeServices = alternativeServices.map((x) => x.val);
            setExtraServices(alternativeServices);
        } catch (e) {
            console.error("fetchExtraServices", e);
            setShowErrorMessage(true);
        } finally {
            setIsExtraServicesLoading(false);
        }
    }

    const clearAfterField = (field) => {
        switch (field) {
            case 'dest':
                setSelectedProgram(null);
                setSelectedPackage(null);
                setSelectedDepartureCity(null);
                setSelectedInps(null);
                setSelectedExtraServices([]);
                setExtraServices([]);
                setInpsList([]);
                break;
            case 'pack':
                setSelectedPackage(null);
                setSelectedDepartureCity(null);
                setSelectedInps(null);
                setSelectedExtraServices([]);
                setExtraServices([]);
                setInpsList([]);
                break;
            case 'period':
                setSelectedDepartureCity(null);
                setSelectedInps(null);
                setSelectedExtraServices([]);
                setExtraServices([]);
                setInpsList([]);
                break;
            case 'city':
                setSelectedInps(null);
                setSelectedExtraServices([]);
                setExtraServices([]);
                setInpsList([]);
                break;
        }
    }

    const onSubmit = async () => {
        try {
            const payload = buildPayload();
            clearAfterField('dest');
            setLoading(true);
            const resultEdit = await editPackage(payload);
            setShowPracticeSuccess(true);
        } catch (e) {
            console.error(e);
            setShowPracticeError(true);
            setShowPracticeErrorMessage("Si è verificato un errore durante la modifica della pratica. Contatta il servizio di assistenza per ulteriori informazioni");
        } finally {
            setLoading(false);
        }
    };

    const getPractice = async () => {
        try {
            const data = await fetchPractice(id);

            if ((data.editableInClubArea ?? false) && (data.practiceProductDivisionId === 1 || data.practiceProductDivisionId === 3) && (data.serviceSnapshots.filter(x => x.alternativeLabel === 'assistenti').length === 0)) {
                setPractice(data);
            } else {
                setErrorMessage("La pratica non è modificabile!");
                setShowErrorMessage(true);
            }
        } catch (e) {
            setShowErrorMessage(true);
        }
    }

    const showEditButton = (selectedDepartureCity && (((inps?.length > 0) && (PRACTICE_TYPES[practice?.practiceType] === PRACTICE_TYPE_INPS)) ? selectedInps : true) && ((selectedExtraServices?.length === extraServices?.length && selectedExtraServices?.length > 0)));

    useEffect(() => {
        if (!practice) getPractice()
    }, []);

    useEffect(() => {
        if (selectedDepartureCity || selectedInps || (selectedExtraServices && selectedExtraServices?.length > 0)) {
            refreshPreview();
        }
    }, [selectedDepartureCity, selectedInps, selectedExtraServices]);

    useEffect(() => {
        if (practice) fetchDestinations();
    }, [practice]);

    useEffect(() => {
        //Fetch packages based on selected destination
        if (selectedDestination) {
            clearAfterField('dest');
            fetchPrograms();
        }
    }, [selectedDestination]);

    useEffect(() => {
        //Fetch periods based on selected package
        if (selectedProgram) {
            clearAfterField('pack');
            fetchPackages();
        }
    }, [selectedProgram]);

    useEffect(() => {
        //Fetch departure cities based on selected period
        if (selectedPackage) {
            clearAfterField('period');
            fetchDepartureCities();
        }
    }, [selectedPackage]);

    useEffect(() => {
        //Fetch inps and extra services based on selected departure city
        if (selectedDepartureCity) {
            clearAfterField('city');
            fetchInps();
            fetchExtraServices();
        }
    }, [selectedDepartureCity]);


    if (showErrorMessage || !id) {
        return <ErrorAlert>{errorMessage}</ErrorAlert>;
    }

    if (!practice || loading) {
        return <Spinner/>;
    }
    console.info("extraServices", extraServices);

    return (
        <>
            <FormContainer>
                <FormContainerCustom>
                    <Content item xs={9}>
                        <FormTitle>
                            <BackLink href="/practices" variant="body2">
                                <ArrowBack htmlColor="black"/>
                            </BackLink> Modifica pratica #{practice.code}
                        </FormTitle>

                        {showPracticeSuccess ?
                            <><SuccessAlert>Congratulazioni! La tua prenotazione è stata modificata con
                                successo</SuccessAlert>
                                <br/>
                                <RetryButton
                                    text="Torna alla lista delle prenotazioni"
                                    onClick={() => history.replace('/practices')}
                                />
                            </>
                            :
                            (showPracticeError ?
                                <>
                                    <ErrorAlert>{showPracticeErrorMessage}</ErrorAlert>
                                    <br/>
                                    <RetryButton text="Riprova" onClick={() => window.location.reload()}/>
                                </> :
                                <Grid container>
                                    <Grid item xs={9}>
                                        <OneStepForm
                                            practice={practice}
                                            isExtraServicesLoading={isExtraServicesLoading}
                                            destinations={destinations}
                                            packages={programs}
                                            periods={periods}
                                            departureCities={departureCities}
                                            inps={inps}
                                            extraServices={extraServices}
                                            selectedDestination={selectedDestination}
                                            onSelectDestination={(value) => setSelectedDestination(value)}
                                            selectedPackage={selectedProgram}
                                            onSelectPackage={(value) => setSelectedProgram(value)}
                                            selectedPeriod={selectedPackage}
                                            onSelectPeriod={(value) => setSelectedPackage(value)}
                                            selectedDepartureCity={selectedDepartureCity}
                                            onSelectDepartureCity={(value) => setSelectedDepartureCity(value)}
                                            selectedInps={selectedInps}
                                            onSelectInps={(value) => setSelectedInps(value)}
                                            selectedExtraServices={selectedExtraServices}
                                            onSelectedExtraServices={(selectedExtraServiceId, extraServiceGroup) => {
                                                //Prelevo uno pseudo valore univoco per identificare se il servizio fa parte o no di un gruppo già selezionato
                                                const groupName = extraServiceGroup.title + extraServiceGroup.subtitle;
                                                //Salvo in una variabile una copia dei servizi già selezionati
                                                let newSelectedExtraServices = [...selectedExtraServices];
                                                //Controllo innanzitutto se esiste già un servizio nello stesso gruppo di quello selezionato
                                                const extraServiceFoundInSameGroup = newSelectedExtraServices.some(x => x.groupName === groupName);

                                                if (extraServiceFoundInSameGroup) {
                                                    //All'interno dell'array ho già un servizio dello stesso gruppo
                                                    //Adesso controllo che io non abbia cliccato sullo stesso elemento
                                                    const sameExtraServiceSelectedInSameGroup = selectedExtraServiceId === newSelectedExtraServices.find(x => x.groupName === groupName)?.value;
                                                    if (!sameExtraServiceSelectedInSameGroup) {
                                                        //Ho selezionato un altro elemento dello stesso gruppo
                                                        //quindi questo va sostituito con quello inserito
                                                        //Salvo quindi nel nuovo array, quello senza questo elemento
                                                        newSelectedExtraServices = newSelectedExtraServices.filter(x => x.groupName !== groupName);
                                                        //Aggiungo in questo array quello appena selezionato
                                                        newSelectedExtraServices.push({
                                                            groupName,
                                                            value: selectedExtraServiceId
                                                        });
                                                        setSelectedExtraServices(newSelectedExtraServices);
                                                    }
                                                } else {
                                                    //Non ho ancora selezionato nessun elemento dello stesso gruppo
                                                    //Posso quindi aggiungerlo senza problemi
                                                    newSelectedExtraServices.push({
                                                        groupName,
                                                        value: selectedExtraServiceId
                                                    });
                                                    setSelectedExtraServices(newSelectedExtraServices);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <EditPracticeSidebar preview={preview}/>
                                    </Grid>
                                </Grid>)}
                    </Content>
                </FormContainerCustom>
            </FormContainer>

            {
                showEditButton &&
                <EditButton text="Modifica" onClick={onSubmit}/>
            }

            {preview?.modificationSurcharge &&
                <><WarningAlert>Le ricordiamo che per effettuare la modifica verrà caricata una quota
                    pari a {formatPrice(preview.modificationSurcharge)}, già inclusa nel totale</WarningAlert><br/></>}


            <StatusAlert visible={showAlert}>
                Aggiornamento avvenuto con successo
            </StatusAlert>
        </>
    );
};

export default EditPractice;

const FormContainer = styled(Paper)`
  background-color: ${props => props.theme.palette.grey[200]};
`;

const BackLink = styled(Link)`
  align-self: center;
`;

const FormContainerCustom = styled(Grid).attrs({
    container: true,
    spacing: 2,
    alignItems: 'center',
})`
  margin: 4px 0;
`;
const Content = styled(Grid)`
  margin: auto
`;

export const EditButton = styled(Button).attrs({
    variant: 'contained',
})`
  display: block;
  margin: 32px auto;
`;

export const RetryButton = styled(Button).attrs({
    variant: 'contained',
})`
  display: block;
  margin: 32px auto;
`;
