import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import Button from '../components/Button';

export default function Layout({children}) {
  return (
    <Root>
      <Children>{children}</Children>

      <FooterContainer>
        <FooterText>
          Per assistenza <br />
          da Lun a Ven: 8:30 - 17:30
        </FooterText>
        <ContactInfoText>
          0736.343440 - 0736.336339 <br />
          club@giocamondo.it
        </ContactInfoText>

        <RegulationsButton
          text={
            <>
              Maggiori informazioni e regolamento GiocamondoClub{' '}
              <ArrowChar>&nbsp;&rarr;</ArrowChar>
            </>
          }
          //href="https://docs.google.com/document/d/1PbyulExMkTyyGX-kco7EyukLIGI0mqw_wMgT0w953ns/edit"
          href="http://giocamondo.it/club"
          rel="noopener noreferrer"
          target="_blank"
        />

        <PolicyContainer>
          <Typography>
            <a
              href="https://www.iubenda.com/privacy-policy/883329"
              className="iubenda-white no-brand iubenda-embed"
              title="Privacy Policy "
            >
              Privacy Policy
            </a>

            <a
              href="https://www.iubenda.com/privacy-policy/883329/cookie-policy"
              className="iubenda-white no-brand iubenda-embed"
              title="Cookie Policy "
            >
              Cookie Policy
            </a>
            <br/>
            <a href="#" class="iubenda-cs-preferences-link">Aggiorna i consensi</a>
          </Typography>
        </PolicyContainer>
      </FooterContainer>
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 16px;
  background-color: ${props => props.theme.palette.grey[100]};
`;

const PolicyContainer = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const RegulationsButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
  size: 'small',
})`
  display: block;
  margin: 16px 0;
  color: ${props => props.theme.palette.common.grey};
  box-shadow: none;

  height: inherit;
  font-size: 12px;
  background-color: #fff;
  border: solid 2px #d5d5d5;
`;

const FooterText = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})``;

const ContactInfoText = styled(FooterText).attrs({
  color: 'primary',
})`
  text-decoration: underline;
`;

const ArrowChar = styled.span`
  font-size: 24;
  padding-bottom: 3px;
`;
