import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import ExtraServiceMobile from "./ExtraServices/ExtraServiceMobile";
import {Spinner} from "../utils/commonStyledComponents";
import {formatPrice} from "../utils/commonFunctions";
import {PRACTICE_TYPES} from "./PracticeItem";
import {PRACTICE_TYPE_INPS} from "../utils/constants";

const OneStepForm = ({
                         practice,
                         destinations,
                         packages,
                         periods,
                         departureCities,
                         inps,
                         extraServices,
                         selectedDestination, onSelectDestination,
                         selectedPackage, onSelectPackage,
                         selectedPeriod, onSelectPeriod,
                         selectedDepartureCity, onSelectDepartureCity,
                         selectedInps, onSelectInps,
                         selectedExtraServices, onSelectedExtraServices,
                         isExtraServicesLoading,
                     }) => {

    const renderChooseLocation = () => <div className="edit-practice-step1-box">
        <Title>Scelta soggiorno</Title>
        <GridRow>
            <Grid item sm={4} xs={12}>
                <FormControl fullWidth={true}>
                    <Select
                        value={selectedDestination}
                        onChange={(event) => onSelectDestination(event.target.value)}
                    >
                        {(destinations?.length === 0) &&
                            <MenuItem disabled>Non ci sono paesi di destinazione!</MenuItem>}
                        {destinations?.map((d) => <MenuItem value={d.value}>{d.label}</MenuItem>)}
                    </Select>
                    <FormHelperText>Seleziona paese di destinazione *</FormHelperText>
                </FormControl>
            </Grid>
            {selectedDestination && <Grid item sm={8} xs={12}>
                <FormControl fullWidth={true}>
                    <Select
                        value={selectedPackage}
                        onChange={(event) => onSelectPackage(event.target.value)}
                    >
                        {(packages?.length === 0) && <MenuItem disabled>Non ci sono programmi!</MenuItem>}
                        {packages?.map((p) => <MenuItem value={p.value}>{p.label}</MenuItem>)}
                    </Select>
                    <FormHelperText>Programma *</FormHelperText>
                </FormControl>
            </Grid>}
        </GridRow>
        {selectedPackage && <GridRow>
            <Grid item sm={8} xs={12}>
                <FormControl fullWidth={true}>
                    <Select
                        value={selectedPeriod}
                        onChange={(event) => onSelectPeriod(event.target.value)}
                    >
                        {(periods?.length === 0) && <MenuItem disabled>Non ci sono periodi!</MenuItem>}
                        {periods?.map((p) => <MenuItem value={p.value}>{p.label} - {formatPrice(p.price)}</MenuItem>)}
                    </Select>
                    <FormHelperText>Seleziona periodo 2023 *</FormHelperText>
                </FormControl>
            </Grid>
            {selectedPeriod && <Grid item sm={4} xs={12}>
                <FormControl fullWidth={true}>
                    <Select
                        value={selectedDepartureCity}
                        onChange={(event) => onSelectDepartureCity(event.target.value)}
                    >
                        {(departureCities?.length === 0) &&
                            <MenuItem disabled>Non ci sono città di partenza!</MenuItem>}
                        {departureCities?.map((dc) => <MenuItem value={dc.value}>{dc.label}</MenuItem>)}
                    </Select>
                    <FormHelperText>Città di partenza *</FormHelperText>
                </FormControl>
            </Grid>}
        </GridRow>}
    </div>

    const renderChooseInps = () => selectedDepartureCity && (inps?.length > 0) && (PRACTICE_TYPES[practice?.practiceType] === PRACTICE_TYPE_INPS) &&
        <div className="edit-practice-step2-box">
            <Title>Bando INPSieme 2023</Title>
            <Subtitle>Indica l'ISEE 2023 e nel riepilogo della prenotazione troverai la voce 'Costo al netto del
                contributo', che corrisponde alla differenza tra il totale da pagare e la quota che ti rimborserà
                l'INPS (se vincitore), come riportato nel bando 2023.</Subtitle>
            <GridRow>
                <Grid item sm={8} xs={12}>
                    <FormControl fullWidth={true}>
                        <Select
                            value={selectedInps}
                            onChange={(event) => onSelectInps(event.target.value)}
                        >
                            {inps?.map((dc) => <MenuItem value={dc.value}>{dc.label}</MenuItem>)}
                        </Select>
                        <FormHelperText>Seleziona ISEE</FormHelperText>
                    </FormControl>
                </Grid>
            </GridRow>
        </div>;

    const renderChooseExtraServices = () => selectedDepartureCity && (extraServices?.length > 0) &&
        <div className="edit-practice-step2-box">
            <Title>SERVIZI EXTRA</Title>
            {extraServices?.map((es) =>
                <ExtraServiceMobile
                    extraservice={es}
                    onSelectExtraService={(val, serviceId) => onSelectedExtraServices(val, es)}
                    selectedExtraServices={selectedExtraServices}
                />)}
        </div>;

    const renderSpace = () => <div className="edit-practice-step2-spacer"></div>;

    const renderOtherChoices = () => isExtraServicesLoading ? <Spinner/> : <>
        {renderChooseInps()}
        {renderChooseExtraServices()}
    </>

    return (
        <>
            {renderChooseLocation()}

            {renderOtherChoices()}

            {renderSpace()}
        </>
    );
};

OneStepForm.propTypes = {
    practice: PropTypes.object,
    destinations: PropTypes.array,
    packages: PropTypes.array,
    periods: PropTypes.array,
    departureCities: PropTypes.array,
    inps: PropTypes.array,
    extraServices: PropTypes.array,
    selectedDestination: PropTypes.string,
    onSelectDestination: PropTypes.func,
    selectedPackage: PropTypes.number,
    onSelectPackage: PropTypes.func,
    selectedPeriod: PropTypes.number,
    onSelectPeriod: PropTypes.func,
    selectedDepartureCity: PropTypes.string,
    onSelectDepartureCity: PropTypes.func,
    selectedInps: PropTypes.string,
    onSelectInps: PropTypes.func,
    selectedExtraServices: PropTypes.array,
    onSelectedExtraServices: PropTypes.func,
    isExtraServicesLoading: PropTypes.bool,
};

export default OneStepForm;

const GridRow = styled(Grid).attrs({
    container: true,
    spacing: 2,
    alignItems: 'center',
})`
  margin: 4px 0;
`;

const Title = styled(Typography).attrs({
    variant: 'h3',
})``;
const Subtitle = styled(Typography).attrs({
    variant: 'body2',
})``;
